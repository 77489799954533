//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IamListToolbar from '@/modules/iam/components/iam-list-toolbar.vue';
import IamListFilter from '@/modules/iam/components/iam-list-filter.vue';
import IamListTable from '@/modules/iam/components/iam-list-table.vue';
import i18n from '@/vueI18n';

export default {
  name: 'app-iam-list-page',

  components: {
    [IamListToolbar.name]: IamListToolbar,
    [IamListFilter.name]: IamListFilter,
    [IamListTable.name]: IamListTable,
  },

  data() {
    return {
      tab: 'admins',
    }
  },
  computed:{
    language(){
    return i18n.locale
  },
  },
  
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('menu.users') 
    },
  },

  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
};
